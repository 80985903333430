import React from 'react';
import tw from 'twin.macro';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Hero from '../components/hero/Hero';
import { ContainerLayout } from '../themes/Layouts';
import ButtonOutLine from '../components/buttons/ButtonOutLine';
import TitleSection from '../components/section/TitleSection';
import SliderQuotes from '../components/slider/SliderQuotes';
import SEO from '../components/SEO';
import ReactGA from "react-ga4";
import HeroEvent from '../components/hero/HeroEvent';
import MoreNews from '../components/section/MoreNews';
import Slider from '../components/slider/Slider';

const Container = tw(ContainerLayout)`grid grid-cols-4 md:grid-cols-12 overflow-hidden`;
const ContainerSlider = tw(ContainerLayout)`px-[0.2rem] pt-[2rem] md:pt-[4.5rem] mb-[6rem]`;
const MapContainer = styled.div`
    ${tw`relative flex justify-center items-center col-span-4 md:col-span-6 h-[27rem] md:h-full bg-center bg-cover`}
    background-image: url('/images/home-map-small.jpg');
    background-size: cover;
    background-repeat: no-repeat;

    @media (min-width: 768px) {
        background-image: url('/images/home-map.jpg');
    }
`;

const Image = tw(motion.img)`col-span-12 md:col-span-6`;
const TextBlock = tw(motion.div)`col-span-12 md:col-span-6 text-white px-[1rem] md:px-[5rem] py-[9rem]`;

const slidesPhotos = [
    "images/slider-encuentro-vitivinicola/encuentro-vitivinicola.jpg", 
    "images/slider-encuentro-vitivinicola/encuentro-vitivinicola-01.jpg", 
    "images/slider-encuentro-vitivinicola/encuentro-vitivinicola-02.jpg", 
    "images/slider-encuentro-vitivinicola/encuentro-vitivinicola-03.jpg", 
    "images/slider-encuentro-vitivinicola/encuentro-vitivinicola-04.jpg", 
    "images/slider-encuentro-vitivinicola/encuentro-vitivinicola-05.jpg", 
    "images/slider-encuentro-vitivinicola/encuentro-vitivinicola-06.jpg",
    "images/slider-encuentro-vitivinicola/encuentro-vitivinicola-07.jpg", 
    "images/slider-encuentro-vitivinicola/encuentro-vitivinicola-08.jpg",
    "images/slider-encuentro-vitivinicola/encuentro-vitivinicola-09.jpg",
]

const Home = () => {

    ReactGA.send({
		hitType:"pageview",
		page: "/",
	});

    // Función para registrar el evento de clic en el botón de "Descargar libro"
    const handleDownloadBookClick = () => {
        ReactGA.event({
            category: 'Botón',
            action: 'Descargar libro',
            label: 'Libro digital de vinos'
        });
    };

    // Función para registrar el evento de clic en los botones de "Explorar mapa"
    const handleExploreMapClick = () => {
        ReactGA.event({
            category: 'Botón',
            action: 'Ver mapa',
            label: 'Mapa de bodegas'
        });
    };

    const cardVariants = {
        hidden: { opacity: 0, y: 50 }, // Aparece de abajo
        visible: { opacity: 1, y: 0 }, // Se coloca en la posición final
    };

    return (
        <>
            <SEO 
                title="Vino Buenos Aires" 
                description="Conocé a los productores vitivinícolas de la Provincia de Buenos Aires, descubrí bodegas y cultivos de vid de lo más diversos."
                name="Vino Buenos Aires"
                type="article"
            />
            <Hero
                imgbg="./images/hero-home.jpg"
                logo="./images/vino-buenos-aires-logo.svg" 
                subtitle="El Vino Bonaerense está relacionado con familias que con esfuerzo, han luchado para conseguir el sueño del viñedo y el vino para compartir."
            />
            
            {/* <HeroEvent/> */}
            
            <TitleSection text="7° Encuentro de Productores Vitivinícolas"/>

            <ContainerSlider>
                <Slider slideData={slidesPhotos}/>
            </ContainerSlider>
            
            <Container className='rounded-[0.4rem] mb-[5rem]'>
                <div className='col-span-4 md:col-span-6 bg-[#C9B066] text-[white] px-[2.2rem] md:px-[5rem] py-[6rem] md:py-[9rem]'>
                    
                    <motion.h2 
                        className='font-raleway font-bold text-[2.2rem] md:text-[4rem] leading-[2.2rem] md:leading-[4rem] mb-[3rem]'
                        variants={cardVariants}
                        initial="hidden"
                        whileInView="visible" // Se activa la animación cuando entra en vista
                        viewport={{ once: false, amount: 0.2 }} // Controla cuándo se inicia la animación, amount=20%
                        transition={{ duration: 0.8 }} // Duración de la animación
                    >
                        Conocé a los productores de la provincia
                    </motion.h2>
                    <motion.p 
                        className='text-[1.3rem] md:text-[1.4rem] font-medium md:mb-[6rem]'
                        variants={cardVariants}
                        initial="hidden"
                        whileInView="visible" // Se activa la animación cuando entra en vista
                        viewport={{ once: false, amount: 0.2 }} // Controla cuándo se inicia la animación, amount=20%
                        transition={{ duration: 0.8 }} // Duración de la animación
                    >
                        En Buenos Aires te esperan diferentes bodegas y cultivos de vid de lo más diversos. Con la influencia de las sierras, el mar, los médanos y la llanura, también se logró instalar la fabricación de vino.
                    </motion.p>
                    <motion.div 
                        className='hidden md:block'
                        variants={cardVariants}
                        initial="hidden"
                        whileInView="visible" // Se activa la animación cuando entra en vista
                        viewport={{ once: false, amount: 0.2 }} // Controla cuándo se inicia la animación, amount=20%
                        transition={{ duration: 0.8 }} // Duración de la animación
                    >
                        <ButtonOutLine 
                            text="Explorar mapa"
                            path="/mapa"
                            textcolor = "white"
                            bgcolor = "#C9B066" 
                            onClick={handleExploreMapClick} // Registro del clic en "Ver mapa"
                        />
                    </motion.div>
                </div>
                <MapContainer>
                    <div className='md:hidden'>
                        <ButtonOutLine 
                            text="Explorar mapa"
                            path="/mapa"
                            textcolor="white"
                            bgcolor="#C9B066" 
                            onClick={handleExploreMapClick} // Registro del clic en "Ver mapa"
                        />
                    </div>
                </MapContainer>
            </Container>

            <TitleSection text="Grandes Historias"/>

            <ContainerSlider>
                <SliderQuotes/>
            </ContainerSlider>

            <div className='bg-[#C9B066]'>
                <Container className='items-center py-[5rem] md:py-[11.5rem] mb-[6rem] md:mb-[11rem] grid grid-cols-12'>
                    {/* Imagen con animación desde la izquierda */}
                    <Image
                        src="./images/digital-book.png"
                        initial={{ opacity: 0, x: -100 }} // Inicia invisible y desplazada
                        whileInView={{ opacity: 1, x: 0 }} // Aparece al estar en vista
                        exit={{ opacity: 0, x: -100 }} // Desaparece al salir de vista
                        transition={{ duration: 0.8 }}
                        viewport={{ amount: 0.3 }} // La animación se activa al 20% visible
                    />
                    
                    {/* Texto con animación desde la derecha */}
                    <TextBlock
                        initial={{ opacity: 0, x: 100 }} // Inicia invisible y desplazado
                        whileInView={{ opacity: 1, x: 0 }} // Aparece al estar en vista
                        exit={{ opacity: 0, x: 100 }} // Desaparece al salir de vista
                        transition={{ duration: 0.8 }}
                        viewport={{ amount: 0.3 }} // La animación se activa al 20% visible
                    >
                        <h2 className='font-raleway font-bold text-[2.2rem] md:text-[4rem] leading-[4rem] mb-[3rem]'>
                            Accedé al libro digital
                        </h2>
                        <p className='text-[1.4rem] font-medium mb-[6rem]'>
                            Este libro nace a partir de la sanción de una ley de la Provincia de Buenos Aires que, impulsada por el Senador Luis Vivona, fomenta una de las bebidas más nobles que tienen las personas: el vino. En sus páginas encontrarás historias, tradiciones y anécdotas de los productores vitivinícolas que trabajan para poner al Vino de Buenos Aires en lo más alto de la vitivinicultura argentina y mundial.
                        </p>
                        <ButtonOutLine
                            text="Descargar libro"
                            url="/docs/libro-vinos-buenos-aires.pdf"
                            textcolor="white"
                            bgcolor="#C9B066"
                            onClick={handleDownloadBookClick} // Registro del clic en "Descargar libro"
                        />
                    </TextBlock>
                </Container>
            </div>

            <TitleSection text="Noticias"/>
            <MoreNews/>
        </>
    );
}

export default Home;
